import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateCustomEventFormPayload,
  UpdateCustomEventFormPayload,
  CustomEventResponse,
  CustomEventsResponse,
  PaginatedCustomEvents,
} from '@/models/custom-event';

const defaultPaginatedBanners = {
  data: null,
  page: 1,
  limit: 10,
  totalRecords: 0,
  totalPages: 0,
};

@Module({
  namespaced: true,
  dynamic: true,
  name: `banner`,
  store,
})
class BannerStore extends VuexModule {
  isPendingBanners = false;
  isPendingBanner = false;
  isCreatingBanner = false;
  isUpdatingBanner = false;
  isDeletingBanner = false;

  bannersError: any = null;
  bannerError: any = null;
  createBannerError: any = null;
  updateBannerError: any = null;
  deleteBannerError: any = null;
  banners: PaginatedCustomEvents = {
    ...defaultPaginatedBanners,
  };
  banner: CustomEventResponse['data'] | null = null;

  @Mutation
  GET_BANNERS_LOADING() {
    this.isPendingBanners = true;
    this.bannersError = null;
    this.banners = defaultPaginatedBanners;
  }
  @Mutation
  GET_BANNERS_SUCCESS(payload: PaginatedCustomEvents) {
    this.isPendingBanners = false;
    this.banners = {
      ...this.banners,
      ...payload,
    };
  }
  @Mutation
  GET_BANNERS_ERROR(payload: any) {
    this.isPendingBanners = false;
    this.bannersError = payload;
  }

  @Mutation
  GET_BANNER_LOADING() {
    this.isPendingBanner = true;
    this.bannerError = null;
    this.banner = null;
  }
  @Mutation
  GET_BANNER_SUCCESS(payload: CustomEventResponse['data']) {
    this.isPendingBanner = false;
    this.banner = payload;
  }
  @Mutation
  GET_BANNER_ERROR(payload: any) {
    this.isPendingBanner = false;
    this.bannerError = payload;
  }

  @Mutation
  CREATE_BANNER_LOADING() {
    this.isCreatingBanner = true;
    this.createBannerError = null;
  }
  @Mutation
  CREATE_BANNER_SUCCESS(payload: any) {
    this.isCreatingBanner = false;
  }
  @Mutation
  CREATE_BANNERS_ERROR(payload: any) {
    this.isCreatingBanner = false;
    this.createBannerError = payload;
  }

  @Mutation
  UPDATE_BANNER_LOADING() {
    this.isUpdatingBanner = true;
    this.updateBannerError = null;
  }
  @Mutation
  UPDATE_BANNER_SUCCESS() {
    this.isUpdatingBanner = false;
  }
  @Mutation
  UPDATE_BANNERS_ERROR(payload: any) {
    this.isUpdatingBanner = false;
    this.updateBannerError = payload;
  }

  @Mutation
  DELETE_BANNER_LOADING() {
    this.isDeletingBanner = true;
    this.deleteBannerError = null;
  }
  @Mutation
  DELETE_BANNER_SUCCESS() {
    this.isDeletingBanner = false;
  }
  @Mutation
  DELETE_BANNERS_ERROR(payload: any) {
    this.isDeletingBanner = false;
    this.deleteBannerError = payload;
  }

  @Action
  getBanners({ keyword = '', page = 1, limit = 10 }) {
    this.context.commit(`GET_BANNERS_LOADING`);

    axios
      .get<CustomEventsResponse>(`/custom-event/list`, {
        params: {
          type: 'banner',
          keyword,
          page,
          limit,
        },
      })
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_BANNERS_SUCCESS`, {
            data: response.data.data,
            page: response.headers[`x-page`],
            limit: response.headers[`x-limit`],
            totalRecords: response.headers[`x-total-records`],
            totalPages: response.headers[`x-total-pages`],
          });
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching banners data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`GET_BANNERS_ERROR`, error);
      });
  }

  @Action
  getBanner(id: number) {
    this.context.commit(`GET_BANNER_LOADING`);

    axios
      .get<CustomEventResponse>(`/custom-event/list/${id}`)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_BANNER_SUCCESS`, response.data.data);
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching banners data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`GET_BANNER_ERROR`, error);
      });
  }

  @Action
  createBanner(params: CreateCustomEventFormPayload) {
    this.context.commit(`CREATE_BANNER_LOADING`);

    axios
      .post<CustomEventResponse>(`/custom-event/banner`, params)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`CREATE_BANNER_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created banner.`,
          });
        }

        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating banners data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`CREATE_BANNER_ERROR`, error);
      });
  }

  @Action
  updateBanner(payload: UpdateCustomEventFormPayload) {
    this.context.commit(`UPDATE_BANNER_LOADING`);

    axios
      .put<CustomEventResponse>(
        `/custom-event/banner/${payload.id}`,
        { ...payload },
        { timeout: 60000 }
      )
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`UPDATE_BANNER_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Banner successfully update!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with banner update process. Please try again. Error: ${response.data.message}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`UPDATE_BANNER_ERROR`, error);
      });
  }

  @Action
  deleteBanner(id: number) {
    this.context.commit(`DELETE_BANNER_LOADING`);

    axios
      .delete(`/custom-event/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`DELETE_BANNER_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-info`,
            duration: 3000,
            message: `Banner successfully deleted!`,
          });
        }

        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with banner delete process. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`DELETE_BANNERS_ERROR`, error);
      });
  }
}

export const BannerStores = getModule(BannerStore);
