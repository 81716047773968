








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { BannerStores } from '@/store/banners';
import CustomEventForm from '@/components/custom-events/CustomEventForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    CustomEventForm,
  },
})
export default class AddBanners extends Vue {
  bannerStore = BannerStores;

  unsubscribe: () => void = () => {};

  handleSubmit(payload: any) {
    this.bannerStore.createBanner(payload);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'banner/CREATE_BANNER_SUCCESS') {
        this.$router.replace('/banners');
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
